import axios from 'axios';
import router from '../router';

const { VUE_APP_API_HOST: host, VUE_APP_API_PORT: port } = process.env;

const api = axios.create({
  baseURL: `${host}:${port}`,
});

api.interceptors.request.use(async (config) => {
  if (localStorage.getItem('@GSCMX:access_token')) {
    // eslint-disable-next-line no-param-reassign
    config.headers = {
      ...config.headers,
      Authorization: localStorage.getItem('@GSCMX:access_token'),
    };
  }
  return config;
}, (error) => {
  Promise.reject(error);
});

api.interceptors.response.use((response) => {
  if (response.data.access_token) {
    localStorage.setItem('@GSCMX:access_token', `Bearer ${response.data.access_token}`);
  }
  if (response.data.refresh_token) {
    localStorage.setItem('@GSCMX:refresh_token', response.data.refresh_token);
  }

  return response;
});

api.interceptors.response.use((response) => response, (error) => {
  if (error?.response?.status === 401) {
    localStorage.removeItem('@GSCMX:access_token');
    localStorage.removeItem('@GSCMX:refresh_token');
    router.push({ name: 'Login' });
  } else if (error?.response?.status === 400) {
    return Promise.reject(error.response);
  }
});

export default api;
