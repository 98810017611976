<template>
  <div class="modal-header teste flex ai-c jc-sb fgap3">
    <div class="process-step flex fgap3 ai-c">
      <div class="flex fgap1 ai-c">
        <h2 class="text-primary text-xg" v-html="getFormattedReference"></h2>
        <div v-if="process.step" class="tag-status text-medium text-md" :style="`background-color: ${process.step.color}; color: ${process.step.textColor}; text-align: center; text-transform: uppercase;`">
          {{ process.step.alias }}
        </div>
      </div>

      <div class="flex fgap1 fw-w ai-c">
        <label class=" text-medium text-light mb-0"> Status da Prime: </label>
        <span class="text-bg text-bold"> "{{ process.status }}"</span>
      </div>
    </div>

    <div class="process-actions flex ai-c fgap2">
      <div v-if="!!process.note || !!process.reminder">
        <button class="btn primary solid small" @click.prevent="drawerObs = true">
          Observações
        </button>
      </div>
      <div>
        <button class="btn info solid small flex gap1 ai-c" @click.prevent="drawerTips = true" style="width: 205px">
          <InfoIcon size="1rem"/> <span> Dicas e Instruções de Uso </span>
        </button>
      </div>
      <div @click.prevent="$emit('request-close', true)" class="close-modal">X</div>
    </div>

    <drawer-modal @request-close="drawerObs = false" :handler="drawerObs" title="Observações">
      <template #content>
        <div class="mb-4" v-if="process.note">
          <h4 class="text-bold mb-2">Observações</h4>
          <textarea name="note" id="note" disabled v-model="process.note"></textarea>
        </div>

        <div v-if="process.reminder">
          <h4 class="text-bold mb-2">Lembretes</h4>
          <textarea name="note" id="note" disabled v-model="process.reminder"></textarea>
        </div>
      </template>
    </drawer-modal>

    <drawer-modal @request-close="drawerTips = false" :handler="drawerTips" title="Dicas e Instruções de Uso">
      <template #content>
        <div class="mb-4">
          <div>
            <h3 class="mb-2">Dados Gerais do Processo</h3>
            <h4 class="mb-1">Campos Editáveis</h4>
            <p class="mb-2">
              Os dados dos campos abaixo serão salvos automaticamente ao serem preenchidos, sem a necessidade de clicar em um botão de salvar, pois o sistema realiza a gravação automaticamente.
            </p>
            <h4 class="mb-1">Follow UP</h4>
            <p class="mb-2">
              Todos os campos que estiverem marcados com o check em verde disparam eventos para envio de Follow UP aos clientes. O e-mail de Follow Up é enviado clicar no botão continuar da janela que é exibida após salvar os dados.
            </p>
            <table class="table">
              <thead>
              <tr>
                <th class="text-bold text-center">Campo</th>
                <th class="text-bold text-center">Dica / Instrução </th>
                <th style="text-align: center;">Gera Follow Up?</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="field in getFieldTips()" :key="field.name">
                <td style="vertical-align: middle;" class="text-bold text-center">{{ field.name }}</td>
                <td style="vertical-align: middle;" class=" text-center"><small>{{ field.info }}</small> </td>
                <td style="text-align: center; vertical-align: middle;">
                  <CloseMSIcon style="color: var(--danger-color)" v-if="!field.followUp" size="20px"/>
                  <CheckIcon style="color: var(--success-color)" v-if="field.followUp" size="20px"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <hr class="mb-2 mt-2">
          <div>
            <h3 class="mb-2">Logística do Processo </h3>
            <h4 class="mb-1">Containers</h4>

            <!-- <p>
              <strong> Adicionar </strong>
            </p>
            <p class="mb-1">
              Clique no botão "Adicionar" para criar um novo container.
            </p>

            <p>
              <strong> Editar </strong>
            </p>
            <p class="mb-1">
              Selecione o campo desejado, faça as alterações e elas serão salvas automaticamente.
            </p>

            <p>
              <strong> Remover </strong>
            </p>
            <p class="mb-1">
              Clique no botão "Remover" ao lado do container para excluí-lo.
            </p> -->

            <table class="table">
              <thead>
                <tr>
                  <th class="text-bold text-center" style="text-align: center;">Campo</th>
                  <th class="text-bold text-center" style="text-align: center;">informação</th>
                  <!-- <th style="text-align: center;">Obrigatório</th> -->
                </tr>
              </thead>
              <tbody>
                <tr v-for="fieldContainer in getFieldContainer()" :key="fieldContainer.name">
                  <td style="vertical-align: middle;" class="text-bold text-center"> {{ fieldContainer.name }} </td>
                  <td style="vertical-align: middle;" class=" text-center"> <small>{{ fieldContainer.info }}</small> </td>
                  <!-- <td style="text-align: center; vertical-align: middle;">
                    <CloseMSIcon style="color: var(--danger-color)" v-if="!fieldContainer.required" size="20px"/>
                    <CheckIcon style="color: var(--success-color)" v-if="fieldContainer.required" size="20px"/>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
          <hr class="mb-2 mt-2">
          <div>
            <h3 class="mb-2"> Documentos do Processo </h3>

            <h4 class="mb-2"> Adicionar Documentos</h4>

            <p>
              <strong> Arraste </strong>
            </p>
            <p class="mb-1">
              Arraste e solte um ou mais documentos no local onde está escrito Enviar DOCS
            </p>

            <p>
              <strong> Preencha os Campos </strong>
            </p>
            <p class="mb-1">
              Após soltar os documentos digite a Descrição e selecione o Tipo do Documento
            </p>

            <p>
              <strong> Envie os Documentos </strong>
            </p>
            <p class="mb-1">
              Após preencher os campos, clique em "Enviar Documento" para concluir o processo
            </p>

            <table class="table mb-2">
              <thead>
              <tr>
                <th class="text-bold text-center">Tipo de Documento</th>
                <th class="text-bold text-center">informação</th>
                <th style="text-align: center;">Gera Follow Up?</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="typeDoc in getTypeDocs()" :key="typeDoc.name">
                <td style="vertical-align: middle;" class="text-bold text-center">{{ typeDoc.name }}</td>
                <td style="vertical-align: middle;" class=" text-center"><small>{{ typeDoc.info }}</small> </td>
                <td style="text-align: center; vertical-align: middle;">
                  <CloseMSIcon style="color: var(--danger-color)" v-if="!typeDoc.followUp" size="20px"/>
                  <CheckIcon style="color: var(--success-color)" v-if="typeDoc.followUp" size="20px"/>
                </td>
              </tr>
              </tbody>
            </table>

          </div>
        </div>
      </template>
    </drawer-modal>
  </div>
</template>

<script>
import {
  CloseMSIcon,
  CheckIcon,
  InfoIcon,
} from '@/components/Icons';
import { mapActions } from 'vuex';
import DrawerModal from './DrawerModal.vue';
import Table from './Table.vue';

export default {
  name: 'ModalProcessHeader',
  components: {
    DrawerModal,
    Table,
    CloseMSIcon,
    CheckIcon,
    InfoIcon,
  },
  data() {
    return {
      drawerObs: false,
      changed: false,
      drawerTips: false,
    };
  },
  computed: {
    getFormattedReference() {
      return this.getTypeOfProcessReference();
    },
  },
  props: {
    process: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    ...mapActions(['toggleLoading']),
    ...mapActions('process', ['patchProcess']),
    getTypeOfProcessReference() {
      if (this.process === null || this.process.identification === null) return 'N/I';

      let ref = '';
      let typeOfProcess = '';
      const zerosToLeft = 5;

      if (this.process.typeOfProcess !== null) {
        typeOfProcess = this.process.typeOfProcess;
      }

      if (typeOfProcess === 'ASSESSORIA') {
        ref = `<strong>A</strong>${this.zeroPad(this.process.identification, zerosToLeft)}`;
      } else if (typeOfProcess === 'ENCOMENDA') {
        ref = `<strong>E</strong>${this.zeroPad(this.process.identification, zerosToLeft)}`;
      } else {
        ref = `<strong>PRI</strong>${this.zeroPad(this.process.identification, zerosToLeft)}`;
      }

      return ref;
    },
    zeroPad(num, places) {
      const zero = places - num.toString().length + 1;

      return Array(+(zero > 0 && zero)).join('0') + num;
    },
    async inputUpdate(field) {
      if (this.changed) {
        const data = {
          identification: this.process.identification,
          fieldUpdated: field,
          data: this.process,
        };
        await this.patchProcess(data);
      }
    },

    getFieldTips() {
      return [
        {
          name: 'Licença de Importação',
          info: 'Informar se o processo Não Possui Licença ou Possui Licença: Por padrão é exibido Não Possui Licença (em vermelho), mas se clicar em cima o botão fica verde e o texto é alterado para Possui Licença',
          followUp: false,
        },
        {
          name: 'Status do Parceiro',
          info: 'Texto que descreve a situação atual / status do processo',
          followUp: false,
        },
        {
          name: 'Referência do Parceiro',
          info: 'A referência interna de sua escolha para ser possível identificar este processo em seus sitemas',
          followUp: false,
        },
        {
          name: 'Navio de Embarque',
          info: 'Nome do Navio de Embarque',
          followUp: false,
        },
        {
          name: 'Nº CE Mercante',
          info: 'Número do CE Mercante',
          followUp: false,
        },
        {
          name: 'Presença',
          info: 'Data da Presença de Carga',
          followUp: false,
        },
        {
          name: 'Vistoria RFB',
          info: 'Data da Vistoria da RBF (Canal Vermelho)',
          followUp: true,
        },
        {
          name: 'Vistoria do MAPA',
          info: 'Data da Vistoria do MAPA',
          followUp: true,
        },
        {
          name: 'Liberação MAPA',
          info: 'Data da Liberação do MAPA',
          followUp: true,
        },
        {
          name: 'Vínculo no Recinto',
          info: 'Data de Vínculo no Recinto',
          followUp: false,
        },
        {
          name: 'Situação do Siscarga',
          info: 'Informar se o Siscarga está Pendente ou Liberado',
          followUp: false,
        },
        {
          name: 'Siscarga',
          info: 'Data do Siscarga',
          followUp: false,
        },
        {
          name: 'TFA',
          info: 'Informar se o TFA está Pendente ou Liberado',
          followUp: false,
        },
        {
          name: 'Liberação AWB / BL',
          info: 'Data de Liberação do AWB ou BL',
          followUp: false,
        },
        {
          name: 'Faturamento do Recinto',
          info: 'Data de Faturamento do Recinto',
          followUp: false,
        },
        {
          name: 'Comex',
          info: 'Data da Liberação no Comex',
          followUp: false,
        },
        {
          name: 'Desova',
          info: 'Informar Sim ou Não para a Desova ',
          followUp: false,
        },
        {
          name: 'Data de Desova',
          info: 'Data da Desova (Apenas ficará visível se a Desova estiver macada como Sim)',
          followUp: false,
        },
        {
          name: 'Data de Digitação',
          info: 'Data de Digitação da DI',
          followUp: false,
        },
        {
          name: 'Data de Registro',
          info: 'Data de Registro da DI',
          followUp: false,
        },
        {
          name: 'Número da DI',
          info: 'Número de Registro da DI',
          followUp: false,
        },
        {
          name: 'Protocolo de Registro da DI',
          info: 'Número do Protocolo de Registro da DI',
          followUp: false,
        },
        {
          name: 'Data de Desembaraço',
          info: 'Data de Desembaraço da DI',
          followUp: false,
        },
        {
          name: 'Canal',
          info: 'Selecionar um Canal de Parametrização da DI',
          followUp: false,
        },

      ];
    },
    getFieldContainer() {
      return [
        {
          name: 'Container',
          info: 'Identificação do Container',
          required: true,
        },
        {
          name: 'Chegada',
          info: 'Data de Chegada do Container',
          required: true,
        },
        {
          name: 'Freetime',
          info: 'Quantidade de Dias de Freetime',
          required: true,
        },
        {
          name: 'Deadline',
          info: 'Deadline do Container (Chegada + Freetime)',
          required: true,
        },
        {
          name: 'Devolução',
          info: 'Data de Devolução do Container',
          required: false,
        },
        {
          name: 'Demurrage',
          info: 'Valor de Demurrage do Container',
          required: false,
        },
        {
          name: 'Reparo',
          info: 'Valor de Reparo do Container',
          required: false,
        },
      ];
    },
    getTypeDocs() {
      return [
        {
          name: 'DI',
          info: 'Documento do Extrato da DI em PDF. Antes de enviar, certifique-se de ter preenchido: Número, Data e Protocolo de Registro da DI.',
          followUp: true,
        },
        {
          name: 'CI',
          info: 'Documento do Comprovante da DI em PDF.',
          followUp: true,
        },
        {
          name: 'Licença de Importação',
          info: 'Documentos de LIs em PDF.',
          followUp: false,
        },
        {
          name: 'AWB / HAWB',
          info: 'AWB / HAWB em PDF.',
          followUp: false,
        },
        {
          name: 'XML DI',
          info: 'XML de Registro da Declaração de Importação.',
          followUp: true,
        },
        {
          name: 'Documentos Originais',
          info: 'Documentos Originais em PDF.',
          followUp: false,
        },
        {
          name: 'BL',
          info: 'BL em PDF.',
          followUp: false,
        },
      ];
    },
  },
  updated() {
    this.changed = true;
  },
};
</script>

<style scoped>
  .close-modal {
    padding: 0 1rem;
    font-size: 1.5rem;
    font-weight: 700;
    cursor: pointer;
  }
  .modal-header {
    border-bottom: var(--input-border);
    padding: 0.3rem 0 0.5rem;
  }

  .tag-status {
    padding: 0.75rem 1.5rem;
    font-weight: 700;
    border-radius: var(--medium-border-radius);
    color: var(--white-color);
  }

  .no-click-message {
    /* background-color: var(--danger-color) !important; */
    padding: 0.5rem  1rem;
    color: #000000;
    border-radius: var(--round-border-radius);
    font-size: 0.9rem;
    font-weight: 700;
  }

  .input-status-custom-broker {
    min-width: 500px;
    font-weight: 700 !important;
    font-size: 1.25rem !important;
  }

 @media (max-width: 1080px) {
  .modal-header  {
    flex-wrap: wrap !important;
  }
  .process-step {
    flex-wrap: wrap !important;
  }
  .process-actions {
    flex-wrap: wrap !important;
  }
 }
</style>
