<template>
  <transition mode="out-in" name="modalProcess">
    <div v-if="handler" class="modal-process">
      <div class="wrapper">

        <ModalProcessHeader
          :process="process"
          @request-close="() => { $emit('request-close', true); }"
          :disableClick="noClick"
        />

        <div v-show="showEnableMessage" class="mt-2 p-2 alert-edit medium-border-radius flex ai-c jc-sb fgap2">
          <h4 class="text-white">O processo está disponível para atualização. Deseja desbloquear?</h4>

          <button @click.prevent="enableProcessEdit" type="button" class="btn solid info small">
            Desbloquear
          </button>
        </div>

        <ModalProcessSummary :process="process" :disableClick="noClick" :noClickMessage="noClickMessage" />

        <div class="modal-tabs mt-2">
          <ModalProcessTabList
            :activeTab="activeTab"
            :docs="data.documents"
            :process="process"
            @tabClick="(tab) => (activeTab = tab)"
          />

          <div class="tab-content">
            <ModalProcessTabGeneral :activeTab="activeTab" :disableClick="noClick"
                                    :process="process"/>

            <ModalProcessTabLogistics
              :activeTab="activeTab"
              :containers="data.containers"
              :disableClick="noClick"
              :process="process"
            />

            <ModalProcessTabDocuments
              :activeTab="activeTab"
              :disableClick="noClick"
              :docs="data.documents"
              :process="process"
            />

            <ModalProcessTabHistory :activeTab="activeTab" :process="process"/>

            <!-- <ModalProcessTabIntegration :active-tab="activeTab" :process="process"/> -->
          </div>
        </div>
      </div>

      <Modal
        :backgroundOpacity="0.95"
        :handler="reminderOpen"
        :maxWidth="40"
        :useActions="true"
        @request-close="reminderOpen = false"
      >
        <template #content>
          <h3 class="text-center text-bold mb-2">Lembrete</h3>
          <p class="text-center">{{ process.reminder }}</p>
        </template>

        <template #cancel-button>
          <a
            class="btn solid primary"
            href="#"
            @click.prevent="reminderOpen = false"
          >FECHAR</a
          >
        </template>
      </Modal>

      <Modal
        :backgroundOpacity="0.95"
        :closeButton="false"
        :handler="modalAction"
        :maxWidth="40"
        :useActions="true"
      >
        <template #content>
          <div class="text-center text-bold mb-2 text-bg" v-html="modalActionTitle">
          </div>
          <p v-for="item in actionMessages" :key="item" class="text-center">{{ item }}</p>
        </template>

        <template #confirm-button>
          <a
            class="btn solid success"
            href="#"
            @click.prevent="() => dispatchProcessAction(false)"
          >
            CONFIRMAR
          </a>
        </template>

        <template #cancel-button>
          <a
            class="btn solid danger mr-2"
            href="#"
            @click.prevent="() => cancelProcessAction()"
          >
            CANCELAR
          </a>
        </template>
      </Modal>
    </div>
  </transition>
</template>

<script>
import { mapState, mapActions } from 'vuex';

import Modal from '@/components/Modal.vue';

import ModalProcessHeader from '@/components/ModalProcessHeader.vue';
import ModalProcessSummary from '@/components/ModalProcessSummary.vue';
import ModalProcessTabList from '@/components/ModalProcessTabList.vue';
import ModalProcessTabGeneral from '@/components/ModalProcessTabGeneral.vue';
import ModalProcessTabLogistics from '@/components/ModalProcessTabLogistics.vue';
import ModalProcessTabDocuments from '@/components/ModalProcessTabDocuments.vue';
import ModalProcessTabHistory from '@/components/ModalProcessTabHistory.vue';
import { actions } from '@/services/webocket';
// import ModalProcessTabIntegration from '@/components/ModalProcessTabIntegration.vue';

export default {
  name: 'ModalProcess',
  components: {
    // Components
    Modal,
    ModalProcessSummary,
    ModalProcessHeader,
    ModalProcessTabList,
    ModalProcessTabGeneral,
    ModalProcessTabLogistics,
    ModalProcessTabDocuments,
    ModalProcessTabHistory,
    // ModalProcessTabIntegration,
  },
  data() {
    return {
      reminderOpen: false,
      activeTab: 1,
      setInterval,
      noClickMessage: null,
      jsonDataCustomer: null,
      availableToEdit: true,
      showEnableMessage: false,
    };
  },
  methods: {
    ...mapActions('process', ['enableNoClick', 'toggleModalAction', 'dispatchProcessAction', 'cancelProcessAction']),
    checkAbilityToEdit() {
      if (this.openedProcesses[this.process.id] && this.openedProcesses[this.process.id].user !== this.user.person.name) {
        this.showEnableMessage = false;
        this.availableToEdit = false;
        this.enableNoClick(true);
        this.noClickMessage = `O usuário ${this.openedProcesses[this.process.id].user} está atualizando este processo no momento.`;
        return;
      }

      if (!this.openedProcesses[this.process.id] && !this.availableToEdit) {
        this.showEnableMessage = true;
        this.noClickMessage = null;
        return;
      }

      this.showEnableMessage = false;
      this.availableToEdit = true;
      this.enableNoClick(false);
      this.noClickMessage = null;
    },
    async enableProcessEdit() {
      this.socket.send(
        JSON.stringify({
          action: actions.OPEN_PROC,
          data: {
            [this.process.id]: this.user.person.name,
          },
        }),
      );

      this.showEnableMessage = false;
      this.availableToEdit = true;
      await this.enableNoClick(false);
      this.noClickMessage = null;
    },
  },
  updated() {},
  computed: {
    ...mapState('process', ['process', 'noClick', 'modalAction', 'actionMessages', 'modalActionTitle', 'actionMessages', 'openedProcesses', 'socket']),
    ...mapState('auth', ['user']),
  },
  props: {
    handler: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkAbilityToEdit();
    });
  },
  beforeDestroy() {
    this.enableNoClick(false);
  },
  beforeRouteLeave() {
    this.enableNoClick(false);
  },
  watch: {
    // availableToEdit(newVal, oldVal) {
    //   if (newVal && !oldVal) {
    //     console.log('saiu de falso para verdadeiro');
    //   }
    //   console.log(oldVal, newVal);
    // },
    process() {
      this.checkAbilityToEdit();
    },
    openedProcesses() {
      this.checkAbilityToEdit();
    },
  },
};
</script>

<style scoped>
.modal-process {
  position: fixed;
  inset: 0;
  background-color: var(--white-color);
  padding: 1rem;
  z-index: 999;
}

.modal-process .wrapper {
  width: 100%;
  height: 100%;
  background-color: #f2f2f2;
  border: var(--input-border);
  border-radius: var(--medium-border-radius);
  padding: 0.5rem;
  overflow: auto;
}

.alert {
  border-radius: var(--medium-border-radius);
}

.tab-content {
  background-color: var(--white-color);
  padding: 1rem;
  border: var(--input-border);
  border-bottom-left-radius: var(--medium-border-radius);
  border-bottom-right-radius: var(--medium-border-radius);
}

.modalProcess-enter-active,
.modalProcess-leave-active {
  transition: var(--all-transition);
}

.modalProcess-enter,
.modalProcess-leave-to {
  transform: scale(0);
}

.modalProcess-enter-to,
.modalProcess.leave {
  transform: scale(1);
}

.tab-content input,
.tab-content select {
  font-weight: bold;
  height: 40px;
}

.alert-edit {
  background-color: var(--primary-color);
  color: white;
}
</style>

<style>
.result-span {
  font-size: 1rem !important;
  font-weight: bold;
  opacity: 0.7;
}
</style>
