<template>
  <div v-if="activeTab == 2">
    <div class="span-12 medium-border-radius border-form gap2 mb-1 ph-2 pb-2 pt-2">
      <div class="flex mt-1">
        <h6 class="text-bold text-center text-md mb-2"> INFORMAÇÕES</h6>
      </div>
      <div class="columns gap1">

        <div class="span-3 mb-1">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              <BriefcaseIcon class="mr-1"/>
              Incoterm
            </div>
          </div>
          <span class="result-span"> {{process.incoterm ? process.incoterm : 'N/I'}} </span>
        </div>

        <div class="span-3 mb-1">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              <BoxesIcon class="mr-1"/>
              Tipo Embarque
            </div>
          </div>
          <span class="result-span"> {{process.typeOfBoarding ? process.typeOfBoarding : 'N/I'}} </span>
        </div>

        <div class="span-3 mb-1">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              <BoxesIcon class="mr-1"/>
              Via de Transporte
            </div>
          </div>
          <span class="result-span"> {{process.wayOfTransport ? process.wayOfTransport : 'N/I'}} </span>
        </div>

        <div class="span-3 mb-1">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              <ShipIcon class="mr-1"/>
              Veículo / Navio
            </div>
          </div>
          <span class="result-span"> {{process.vehicle ? process.vehicle : 'N/I'}} </span>
        </div>

        <div class="span-3 mb-1">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              <ShipIcon class="mr-1"/> Navio de Embarque
            </div>
          </div>
          <span class="result-span"> {{process.vehicleTranshipment ? process.vehicleTranshipment : 'N/I'}} </span>
        </div>

        <div class="span-3 mb-1">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              <FileIcon class="mr-1"/>
              BL/AWB/CRT
            </div>
          </div>
          <span class="result-span"> {{process.billOfLading ? process.billOfLading : 'N/I'}} </span>
        </div>

        <div class="span-3 mb-1">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              <FileIcon class="mr-1"/>
              Freetime
            </div>
          </div>
          <span class="result-span"> {{process.freetime ? process.freetime : 'N/I'}} </span>
        </div>

        <div class="span-3 mb-1">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              <IndustryIcon class="mr-1"/>
              Forwarder
            </div>
          </div>
          <span class="result-span"> {{process.freightForwarder ? process.freightForwarder.completeName : 'N/I'}} </span>
        </div>

        <div class="span-3 mb-1">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              <IndustryIcon class="mr-1"/>
              Porto de Destino
            </div>
          </div>
          <span class="result-span"> {{process.destinationPort ? process.destinationPort.alias : 'N/I'}} </span>
        </div>

        <div class="span-3 mb-1">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              <IndustryIcon class="mr-1"/>
              Recinto Alfandegado
            </div>
          </div>
          <span class="result-span"> {{process.freightForwarder ? process.customsEnclosure.alias : 'N/I'}} </span>
        </div>

        <div class="span-3 mb-1">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              <IndustryIcon class="mr-1"/>
              Transportadora
            </div>
          </div>
          <span class="result-span"> {{process.conveyor ? process.conveyor.completeName : 'N/I'}} </span>
        </div>

        <div class="span-3 mb-1">
          <div class="flex ai-c jc-sb mb-1">
            <div class="flex ai-c text-light">
              <IndustryIcon class="mr-1"/>
              Armazém Geral
            </div>
          </div>
          <span class="result-span"> {{process.generalWarehouse ? process.generalWarehouse.completeName : 'N/I'}} </span>
        </div>

      </div>
    </div>

    <hr class="mv-2"/>

    <div class="mb-2">
      <h5 class="text-bold">CONTAINERS</h5>
      <p>(Total de {{ process.containers.length }} containers cadastrados)</p>
    </div>

    <div class="offwhite-bg medium-border-radius p-1">
      <div class="table-overflow">
        <table class="process-table">
          <thead>
          <tr>
            <th>Container</th>
            <th class="text-center">Chegada</th>
            <th class="text-center">Freetime</th>
            <th class="text-center">Deadline</th>
            <th class="text-center">Devolução</th>
            <th class="text-right">Demurrage</th>
            <th class="text-right">Reparo</th>
            <!-- <th class="text-right">Ações</th> -->
          </tr>
          </thead>
          <tbody>
            <tr v-for="(container, index) in containers" :key="index">
              <td class="input-cell-container">
                <span class="text-bold">{{ containers[index].container ?? 'N/I' }}</span>
              </td>
              <td class="input-cell-date text-center">
                <span class="text-bold">{{ containers[index].arrival ? formatData(containers[index].arrival) : 'N/I' }}</span>
              </td>
              <td class="input-cell-freetime text-center">
                <span class="text-bold">{{ containers[index].freetime ?? 'N/I' }}</span>
              </td>
              <td class="input-cell-date text-center">
                <span class="text-bold">{{ containers[index].deadline ? formatData(containers[index].deadline) : 'N/I' }}</span>
              </td>
              <td class="input-cell-date text-center">
                <span class="text-bold">{{ containers[index].devolution ? formatData(containers[index].devolution) : 'N/I' }}</span>
              </td>
              <td class="input-cell-money text-right">
                <span class="text-bold">
                  {{ containers[index].demurrage ? `${containers[index].demurrage.toFixed(2).replace('.', ',')}` : '0,00' }}
                </span>
              </td>
              <td class="input-cell-money text-right">
                <span class="text-bold">
                  {{ containers[index].repair ? `${containers[index].repair.toFixed(2).replace('.', ',')}` : '0,00' }}
                </span>
              </td>
            </tr>
          <!-- <tr v-for="(container, index) in containers" :key="index">
            <td class="input-cell-container">
              <Input
                v-model="containers[index].container"
                :disabled="disableClick"
                :required="true"
                className="text-bold"
                name="container"
                placeholder="Container"
                type="text"
                @input="() => inputUpdateContainer(index)"
              />
            </td>
            <td class="input-cell-date">
              <DatePicker v-model="containers[index].arrival" :disabled="disableClick" :fullHeight="true" @input="() => inputUpdateContainer(index)"/>
            </td>
            <td class="input-cell-freetime">
              <Input
                v-model="containers[index].freetime"
                :disabled="disableClick"
                :required="true"
                className="text-bold"
                name="freetime"
                placeholder="Freetime"
                type="text"
                @input="calculateDeadlineAfterSetFreetimeExisting(index, container)"
              />
            </td>
            <td class="input-cell-date">
              <DatePicker v-model="containers[index].deadline" :disabled="disableClick" :fullHeight="true" @input="() => inputUpdateContainer(index)"/>
            </td>
            <td class="input-cell-date">
              <DatePicker v-model="containers[index].devolution" :disabled="disableClick" :fullHeight="true" @input="() => inputUpdateContainer(index)"/>
            </td>
            <td class="input-cell-money">
              <Input
                v-model="containers[index].demurrage"
                :disabled="disableClick"
                :money="true"
                :required="true"
                className="text-bold"
                name="demurrage"
                placeholder="Demurrage"
                type="text"
                @input="() => inputUpdateContainer(index)"
              />
            </td>
            <td class="input-cell-money">
              <Input
                v-model="containers[index].repair"
                :disabled="disableClick"
                :money="true"
                :required="true"
                className="text-bold"
                name="repair"
                placeholder="Reparo"
                type="text"
                @input="() => inputUpdateContainer(index)"
              />
            </td>
            <td class="text-right action-cell">
              <a :style="`${disableClick ? 'pointer-events: none;' : ''}`"
                 class="btn small solid danger" href="#"
                 @click.prevent="removeContainer(containers[index].id)">
                Remover
              </a>
            </td>
          </tr> -->
          <!-- <tr>
            <td class="input-cell-container">
              <Input
                v-model="newContainerData.container"
                :disabled="disableClick"
                :required="true"
                className="text-bold"
                name="container"
                placeholder="Container"
                type="text"
              />
            </td>
            <td class="input-cell-date">
              <DatePicker v-model="newContainerData.arrival" :disabled="disableClick"
                          :fullHeight="true"/>
            </td>
            <td class="input-cell-freetime">
              <Input
                v-model="newContainerData.freetime"
                :disabled="disableClick"
                :required="true"
                className="text-bold"
                name="freetime"
                placeholder="Freetime"
                type="text"
                @input="calculateDeadlineAfterSetFreetime"
              />
            </td>
            <td class="input-cell-date">
              <DatePicker v-model="newContainerData.deadline" :disabled="disableClick" :fullHeight="true"/>
            </td>
            <td class="input-cell-date">
              <DatePicker v-model="newContainerData.devolution" :disabled="disableClick" :fullHeight="true"/>
            </td>
            <td class="input-cell-money">
              <Input
                v-model="newContainerData.demurrage"
                :disabled="disableClick"
                :money="true"
                :required="true"
                className="text-bold"
                name="demurrage"
                placeholder="Demurrage"
                type="text"
              />
            </td>
            <td class="input-cell-money">
              <Input
                v-model="newContainerData.repair"
                :disabled="disableClick"
                :money="true"
                :required="true"
                className="text-bold"
                name="repair"
                placeholder="Reparo"
                type="text"
              />
            </td>
            <td class="action-cell">
              <div class="flex ai-c fgap2 jc-fe">
                <a :style="`${disableClick ? 'pointer-events: none;': ''}`"
                   class="btn small solid success" href="#" @click.prevent="createContainer">Adicionar</a>
              </div>
            </td>
          </tr> -->
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapActions } from 'vuex';
import api from '@/services/api';

import {
  IndustryIcon,
  FileIcon,
  MapPinIcon,
  BoxesIcon,
  BriefcaseIcon,
  ShipIcon,
} from '@/components/Icons';

import DatePicker from '@/components/DataInput/DatePicker.vue';
import Input from '@/components/DataInput/Input.vue';

export default {
  name: 'ModalProcessTabLogistics',
  components: {
    // Icons
    IndustryIcon,
    FileIcon,
    MapPinIcon,
    BoxesIcon,
    BriefcaseIcon,
    ShipIcon,
    // Componenets
    DatePicker,
    Input,
  },
  data() {
    return {
      changed: false,
      newContainerData: {
        container: '',
        arrival: this.process.datesArrivalDate,
        freetime: this.process.freetime,
        deadline: moment.utc(this.process.datesArrivalDate).add(this.process.freetime - 1, 'days').format(),
        devolution: '',
        demurrage: '',
        repair: '',
      },
    };
  },
  methods: {
    ...mapActions(['toggleLoading']),
    ...mapActions('process', ['patchProcess']),
    formatData(data) {
      if (data) {
        return moment(data).format('DD/MM/YYYY');
      }

      return null;
    },
    async inputUpdate(field) {
      if (this.changed) {
        const data = {
          identification: this.process.identification,
          fieldUpdated: field,
          data: this.process,
        };
        await this.patchProcess(data);
      }
    },
    async removeContainer(id) {
      try {
        const response = await api.delete(`/api/public/partner/processes/${this.process.id}/containers/${id}`);
        this.$toast.success(response.data.message);
        this.containers = this.containers.filter((item) => item.id !== id);
      } catch (err) {
        this.$toast.error('Não foi possível remover o container');
      }
    },
    async createContainer() {
      this.toggleLoading(true);

      const requiredFields = [
        { field: 'container', message: 'Container não foi preenchido.' },
        { field: 'arrival', message: 'Data de Chegada não foi preenchido.' },
        { field: 'freetime', message: 'Freetime não foi preenchido.' },
        { field: 'deadline', message: 'Data de Deadline não foi preenchido.' },
      ];

      const messageErroInputs = requiredFields.find((field) => !this.newContainerData[field.field])?.message;

      if (messageErroInputs) {
        this.$toast.error(messageErroInputs);
        this.toggleLoading(false);
        return;
      }

      try {
        const response = await api.post(`/api/public/partner/processes/${this.process.id}/containers`, {
          container: this.newContainerData.container,
          arrival: this.newContainerData.arrival,
          freetime: this.newContainerData.freetime,
          deadline: this.newContainerData.deadline,
          devolution: this.newContainerData.devolution,
          demurrage: parseFloat(this.newContainerData.demurrage.replace('.', '').replace(',', '.')),
          repair: parseFloat(this.newContainerData.repair.replace('.', '').replace(',', '.')),
        });

        if (response.data.code === 'ERROR') {
          this.$toast.error(response.data.message);
          this.toggleLoading(false);
          return;
        }

        this.containers.push(response.data.data);

        this.newContainerData = {
          container: '',
          arrival: this.process.datesArrivalDate,
          freetime: this.process.freetime,
          deadline: this.process.datesArrivalDate ? moment.utc(this.process.datesArrivalDate).add(this.process.freetime - 1, 'days').format() : '',
          devolution: '',
          demurrage: '',
          repair: '',
        };
        this.toggleLoading(false);
      } catch (err) {
        this.$toast.error('Não foi possível adicionar o container');
        this.toggleLoading(false);
      }
    },
    inputUpdateContainer(index) {
      if (this.timer) {
        clearTimeout(this.timer);
      }

      this.timer = setTimeout(async () => {
        this.toggleLoading(true);

        const requiredFields = [
          { field: 'container', message: 'Container não foi preenchido.' },
          { field: 'arrival', message: 'Data de Chegada não foi preenchido.' },
          { field: 'freetime', message: 'Freetime não foi preenchido.' },
          { field: 'deadline', message: 'Data de Deadline não foi preenchido.' },
        ];

        const messageErroInputs = requiredFields.find((field) => !this.containers[index][field.field])?.message;

        if (messageErroInputs) {
          this.$toast.error(messageErroInputs);
          this.toggleLoading(false);
          return;
        }

        const response = await api.put(`/api/public/partner/processes/${this.process.id}/containers`, {
          ...this.containers[index],
          demurrage: parseFloat(this.containers[index].demurrage.replace('.', '').replace(',', '.')),
          repair: parseFloat(this.containers[index].repair.replace('.', '').replace(',', '.')),
        });
        this.containers.map((item) => {
          this.toggleLoading(false);
          if (item.id === response.data.data.id) {
            return response.data.data;
          }
          return item;
        });
      }, 800);
    },
    calculateDeadlineAfterSetFreetime() {
      if (this.newContainerData.arrival) {
        const dataChegada = moment(this.newContainerData.arrival, 'YYYY-MM-DD[T]hh:mm:ss');

        let dataDeadline = dataChegada;

        if (this.newContainerData.freetime && this.newContainerData.freetime !== 'undefined') {
          dataDeadline = dataChegada.add(parseInt(this.newContainerData.freetime - 1), 'days').format('YYYY-MM-DD[T]hh:mm:ss');
        }

        this.newContainerData.deadline = dataDeadline;
      }
    },
    calculateDeadlineAfterSetFreetimeExisting(index, item) {
      const dataChegada = moment(item.arrival, 'YYYY-MM-DD[T]hh:mm:ss');

      let dataDeadline = dataChegada;

      if (item.freetime && item.freetime !== 'undefined') {
        dataDeadline = dataChegada.add(parseInt(item.freetime - 1), 'days').format('YYYY-MM-DD[T]hh:mm:ss');
      }

      item.deadline = dataDeadline;

      this.inputUpdateContainer(index);
    },
  },
  updated() {
    this.changed = true;
  },
  props: {
    process: {
      type: Object,
      default: () => {
      },
    },
    activeTab: {
      type: Number,
      default: 0,
    },
    containers: {
      type: Array,
      default: () => [],
    },
    disableClick: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style scoped>
.border-form {
  border: 1px solid #e2e2e2;
}
.text-right {
  text-align: right !important
}

.text-center {
  text-align: center !important
}

.input-cell-date,
.input-cell-money {
  min-width: 135px !important;
}

.input-cell-container {
  min-width: 160px !important;
}

.input-cell-freetime {
  min-width: 60px !important;
}
.action-cell {
  min-width: unset !important;
}
</style>
